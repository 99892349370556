<template>
  <div id="app">
    <LoginBg :pageTitle="$t('forgotpassword.title')" :isShowRegister=false />
    
    <br />
    <v-layout justify-center>
      <!-- <v-form @submit.prevent="submit"></v-form> -->
      <v-form>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input inputNumber"
            v-model="username"
            type="number"
            autocomplete="username"
            :label="$t('forgotpassword.username')"
            :placeholder="$t('forgotpassword.username')"
            append-icon="phone_iphone"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-row>
            <v-col cols="7">
              <v-text-field
                class="text_input inputNumber"
                v-model="otp"
                type="number"
                autocomplete="otp"
                :label="$t('forgotpassword.otp_code')"
                :placeholder="$t('forgotpassword.otp_code')"
                filled
                hide-details
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-btn class="mt-1" color="#1976D2" width="110" height="50" dark @click="requestOtpClick"><div v-html="$t('forgotpassword.request_otp')"></div></v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="password"
            :label="$t('forgotpassword.password')"
            :placeholder="$t('forgotpassword.password')"
            :append-icon="isShowPassword1 ? 'visibility' : 'visibility_off'"
            @click:append="isShowPassword1 = !isShowPassword1"
            :type="isShowPassword1 ? 'text' : 'password'"
            autocomplete="password"
            filled
            hide-details
            clearable
          />
        </div>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="confirmPassword"
            :label="$t('forgotpassword.confirm_password')"
            :placeholder="$t('forgotpassword.confirm_password')"
            :append-icon="isShowPassword2 ? 'visibility' : 'visibility_off'"
            @click:append="isShowPassword2 = !isShowPassword2"
            :type="isShowPassword2 ? 'text' : 'password'"
            autocomplete="confirmPassword"
            filled
            hide-details
            clearable
          />
        </div>
      </v-form>
    </v-layout>

    <v-layout justify-center>
      <div style="width: 300px; text-align: center;">
        <v-btn class="mr-5" rounded width="100" @click="$router.back()">{{ $t('forgotpassword.back_button') }}</v-btn>
        <v-btn rounded color="#3949AB darken-4" width="180" dark @click="resetPasswordClick" :disabled="!username || !otp || !password || !confirmPassword">{{ $t('forgotpassword.reset_button') }}</v-btn>
      </div>
    </v-layout>
    <br />

    <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay>
    <ShowMessage 
      v-model="isShowMessage" 
      :msg="showmessage_msg"
      :width="showmessage_width"
      :callBack="showmessage_callback">
    </ShowMessage>
  </div>
</template>

<script>
import LoginBg from "@/components/LoginBg"
import ShowOverlay from "@/components/ShowOverlay"
import ShowMessage from "@/components/ShowMessage"
import api from "@/services/api";

export default {
  name: "ForgotPassword",
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      otp: '',
      isShowPassword1: false,
      isShowPassword2: false,

      isShowOverlay: false,
      isShowMessage: false,
      showmessage_msg: "",
      showmessage_width: 400,
      showmessage_callback: null
    };
  },
  components: {
    LoginBg,
    ShowOverlay,
    ShowMessage
  },
  methods: {
    showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
    },
    onShowMessageCallBack() {
      this.$router.back();
    },
    async requestOtpClick() {
      try {
        this.showmessage_callback = null;
        if (this.username?.trim()=="" || this.username?.trim().length!=10) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_phoneno}`, 400);
          return;
        }
        this.isShowOverlay = true;
        const response = await api.resetPassword(this.username);
        if (response.status==200)
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_success}`, 400);
        else
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.send_otp_notsuccess}`, 400);
      } catch (error) {
        this.showMessage(error.response.data.status.toString().concat(' : ').concat(error.response.data.message), 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 1000);
      }
    },
    async resetPasswordClick() {
      try {
        this.showmessage_callback = null;
        if (this.username?.trim()=="" || this.username?.trim().length!=10) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_phoneno}`, 400);
          return;
        }
        if (this.otp?.trim()=="" || this.otp?.trim().length!=6) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_otp}`, 400);
          return;
        }
        if (this.password?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_password}`, 400);
          return;
        }
        if (this.confirmPassword?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_confirm_password}`, 400);
          return;
        }
        if (this.password?.trim()!=this.confirmPassword?.trim()) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.password_not_equal_confirm_password}`, 400);
          return;
        }
        this.isShowOverlay = true;
        const response = await api.confirmResetPassword(
          this.username,
          this.password,
          this.confirmPassword,
          this.otp);
        if (response.status==200) {
          this.showmessage_callback = this.onShowMessageCallBack;
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.reset_password_success}`, 400);
        }
        else
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.reset_password_notsuccess}`, 400);
      } catch (error) {
        this.showMessage(error.response.data.status.toString().concat(' : ').concat(error.response.data.message), 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 1000);
      }
    }
  },
};
</script>

<style scoped>
.text_input {
  background-color: #FAFAFA;
  color: #000000
}
.inputNumber >>> input[type='number'] {
  -moz-appearance:textfield;
}
.inputNumber >>> input::-webkit-outer-spin-button,
.inputNumber >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>